<template>
  <div class="upload_img">
    <el-image
      v-if="postUrl"
      :src="postUrl | filterImgUrl"
      style="width: 100px; height: 100px"
      fit="cover"
      @click="chooseImage"
    >
      <div slot="error">
        {{ postUrl | filterImgUrl }}
        <el-image
          :src="postUrl | filterImgUrl"
          style="width: 100px; height: 100px"
          fit="cover"
          @click="chooseImage"
        ></el-image>
      </div>
    </el-image>

    <div
      v-else
      class="add_photo"
      @click="chooseImage"
      style="margin-bottom: 15px"
    >
      <i class="el-icon-plus"></i>
    </div>
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      @change="uploadImage"
      @click="
        (event) => {
          event.target.value = null;
        }
      "
      style="width: 0; height: 0; position: absolute"
    />
    <el-button
      type="primary"
      icon="el-icon-delete"
      circle
      size="mini"
      style="display: block; margin-left: 34px"
      v-if="postUrl"
      @click="deleteImg"
    ></el-button>
  </div>
</template>
 
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      postUrl: "",
    };
  },
  props: {
    paramsType: {
      type: String,
      default: "",
    },
    paramsImg: {
      type: String,
      default: "",
    },
  },
  watch: {
    // 监听对象中的某个值 也可以直接监听某个对象
    paramsImg(newValue) {
      this.postUrl = newValue;
    },
  },
  filters: {
    // filterImg(url) {
    //   const baseUrl = "https://hrv1-end.yx2.qushiyun.com";
    //   return url.indexOf(baseUrl) != -1 ? url : baseUrl + url;
    // },
  },
  mounted() {
    console.log(this);
  },
  methods: {
    ...mapActions(["uploadFile"]),
    /**
     * 选择图片
     */
    chooseImage() {
      this.$refs.fileInput.click();
    },
    /**
     * 上传图片
     */
    uploadImage(file) {
      console.log(file, "上传");
      this.uploadFile(file.target.files[0])
        .then((res) => {
          console.log(res);
          this.postUrl = res.data.data.full_link;
          this.$emit("emitUploadImg", {
            name: this.paramsType,
            url: this.postUrl,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.msg);
        });
    },
    deleteImg() {
      this.postUrl = "";
      this.$emit("emitUploadImg", {
        name: this.paramsType,
        url: this.postUrl,
      });
    },
  },
};
</script>
 
<style lang = "scss" scoped>
.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}
</style>